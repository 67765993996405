<template>
  <div class="mb-6">
    <h2 v-if="!editMode" class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Create new User
    </h2>
    <h2 v-else class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Edit User
    </h2>
    <div
      class="flex flex-col w-full p-4 space-y-4 bg-white border border-gray-200 shadow-lg md:w-3/4 dark:bg-gray-700 md:p-8 rounded-xl dark:border-gray-500">
      <div class="w-full">
        <label class="">
          <span class="label">Name</span>
          <input type="text" class="input" placeholder="Name" v-model="$v.user.name.$model" />
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Email</span>
          <input type="text" class="input" placeholder="Email" v-model="$v.user.email.$model" />
        </label>
      </div>
      <div v-if="authUser.id === usr.id" class="w-full">
        <label class="">
          <span class="label">Password</span>
          <input type="text" class="input" placeholder="Password" v-model="$v.user.password.$model" />
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Role</span>
          <select v-model="$v.user.role.$model" class="input">
            <option value="" selected disabled>Select user role</option>
            <option value="owner">Owner</option>
            <option value="staff">Staff</option>
          </select>
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">User belongs to which cafe</span>
          <select v-model="$v.user.cafe_id.$model" class="input">
            <option value="" selected>Select user's cafe</option>
            <option v-for="cafe in cafes" :key="cafe.id" :value="cafe.id">
              {{ cafe.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="flex justify-center w-full">
        <button class="w-32 btn" :class="$v.$invalid ? 'bg-gray-600 text-white' : null"
          @click="editMode ? updateUser() : saveUser()">
          {{ editMode ? "Update user" : "Create user" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import isNil from "ramda/es/isNil";
export default {
  name: "CreateUserComponent",
  mixins: [validationMixin],
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        cafe_id: "",
        role: "",
      },
    };
  },
  mounted() {
    this.loadCafes();
    if (this.editMode) {
      this.loadUser();
    }
  },
  computed: {
    ...mapGetters({
      cafes: ["cafe/getCafes"],
      usr: ["user/getUser"],
      authUser: ['auth/getAuthUser']
    }),
    editMode() {
      return !isNil(this.$route.params) && !isNil(this.$route.params.nano_id);
    },
  },
  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required: requiredIf(function () {
          return !this.editMode;
        }),
      },
      role: {
        required,
      },
      cafe_id: {
        required: requiredIf(function (model) {
          return model.role === "staff";
        }),
      },
    },
  },
  methods: {
    loadCafes() {
      this.$store.dispatch("cafe/getCafes");
    },
    loadUser() {
      this.$store.dispatch("user/loadUser", {
        nano_id: this.$route.params.nano_id,
      });
    },
    saveUser() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("user/storeUser", { user: this.user });
      }
    },
    updateUser() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("user/updateUser", {
          nano_id: this.$route.params.nano_id,
          user: this.user,
        });
      }
    },
  },
  watch: {
    usr: {
      handler() {
        if (!isNil(this.usr) && this.editMode) {
          this.user = {
            ...this.usr,
            cafe_id: this.usr.cafe ? this.usr.cafe.id : "",
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

</style>
